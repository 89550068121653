<!--  -->
<template>
  <div class="StationOne flex">
    <div class="StationOne-left">
      <!--四干二号  -->
      <div class="FourDo Card-b-c">
        <Titles :Titles="siteName" />
        <!-- 动画区域 -->
        <div class="animation">
          <Anmation
            :gateNum="Number(valveForm.value) ? Number(valveForm.value) : 0"
            :gateList="options"
            :gateHeight="Number(gateVo.gateHeight)"
            :gateHeightLimit="Number(gateVo.gateHeightLimit)"
            :gateShow="gateShow"
          ></Anmation>
        </div>
        <!-- 阀门区域 -->
        <div class="valve">
          <el-form :rules="rules" ref="ruleForm" v-model="valveForm">
            <!-- 阀门 -->
            <el-form-item label="闸门"
              ><el-select v-model="valveForm.value" @change="cutGate">
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.gateName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <span>状态：</span>
              <span
                v-if="siteItemVo.deviceStatus === 0 && gateVo.gateHeight > 5"
                style="color: green"
                >运行中</span
              >
              <span
                v-else-if="
                  siteItemVo.deviceStatus === 0 && gateVo.gateHeight <= 5
                "
                style="color: red"
                >停止</span
              >
              <span v-else style="color: gray">离线</span>
            </el-form-item>
            <!-- 修改闸门高度 -->
            <el-form-item label="修改闸门高度" class="flex valve-h flex-a-c">
              <el-input v-model="valveForm.valveHeight" maxlength="3" />
              <div style="margin-left: calc(10 / 1920 * 100vw)">(cm)</div>
              <div
                class="flex flex-a-c"
                style="margin-left: calc(16 / 1920 * 100vw)"
              >
                <el-button
                  type="primary"
                  @click="startSluice"
                  :disabled="siteItemVo.deviceStatus === 0 ? false : true"
                  >开启</el-button
                >
                <el-button
                  @click="stopSluice(0)"
                  style="margin: 0 5px"
                  :disabled="siteItemVo.deviceStatus === 0 ? false : true"
                  >关闭</el-button
                >
                <el-button
                  @click="stopSluice(1)"
                  :disabled="siteItemVo.deviceStatus === 0 ? false : true"
                  >急停</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 闸门信息 -->
      <div class="strobe Card-b-c">
        <Titles Titles="闸门信息" />
        <div class="strobeBox flex flex-a-c">
          <!-- 闸门高度 -->
          <div class="strobeBox-height font-w-b flex flex-a-c">
            <span class="font-s-18">闸门高度：</span>
            <span class="font-s-24">{{ gateVo.gateHeight }}</span>
            <span class="font-s-18">(cm)</span>
          </div>
          <!-- 闸门限高 -->
          <div class="strobeBox-headroom font-w-b flex flex-a-c">
            <span class="font-s-18">闸门限高：</span>
            <span class="font-s-24">{{ gateVo.gateHeightLimit }}</span>
            <span class="font-s-18">(cm)</span>
          </div>
        </div>
      </div>
      <!-- 水文，太阳能 -->
      <div class="StationOne-left-bottom">
        <div class="content flex flex-j-s-b">
          <!-- 左侧 -->
          <div class="left">
            <!-- 水文信息 -->
            <div class="hydrology Card-b-c">
              <Titles Titles="水文信息" />
              <div class="hydrologyBox font-w-b">
                <!-- 当前水位 -->
                <div class="warningWater flex flex-a-c">
                  <span class="font-s-18">闸后水位：</span>
                  <span
                    class="font-s-24"
                    :style="{
                      color:
                        siteItemVo.currentLevel > siteItemVo.warningLevel
                          ? 'red'
                          : '',
                    }"
                  >
                    {{ siteItemVo.currentLevel }}
                  </span>
                  <span class="font-s-18">(cm)</span>
                </div>
                <!-- 水位闸前 -->
                <div class="warningWater flex flex-a-c">
                  <span class="font-s-18">闸前水位：</span>
                  <span class="font-s-24">{{ siteItemVo.gateLevel }}</span>
                  <span class="font-s-18">(cm)</span>
                </div>

                <!-- 流量 -->
                <div class="warningWater flex flex-a-c">
                  <span class="font-s-18">瞬时流量：</span>
                  <span class="font-s-24">{{ siteItemVo.traffic }}</span>
                  <span class="font-s-18">(m³/h)</span>
                </div>
                <!-- 流速 -->
                <div class="warningWater flex flex-a-c">
                  <span class="font-s-18">流速：</span>
                  <span class="font-s-24">{{ siteItemVo.velocity }}</span>
                  <span class="font-s-18">(m/s)</span>
                </div>
                <!-- 警戒水位 -->
                <div class="warningWater flex flex-a-c">
                  <span class="font-s-18">警戒水位：</span>
                  <span class="font-s-24 table-num">
                    <el-input
                      id="input1"
                      @blur="test"
                      maxlength="4"
                      v-model="warningLevel"
                    ></el-input>
                  </span>
                  <span class="font-s-18">(cm)</span>
                </div>
              </div>
            </div>
            <!-- 气象信息 -->
            <div class="weather Card-b-c">
              <Titles Titles="气象信息" />
              <div class="weatherBox font-w-b">
                <div class="weatherItem flex flex-a-c">
                  <span class="font-s-18">风速：</span>
                  <span
                    class="font-s-18"
                    v-if="siteItemVo.direction == 0 && siteItemVo.speed == 0"
                    >无</span
                  >
                  <span v-else class="font-s-18"
                    >{{ siteItemVo.direction }}风 {{ siteItemVo.speed }}</span
                  >
                  <!-- <span class="font-s-18">(m/s)</span> -->
                </div>
                <div class="weatherItem flex flex-a-c">
                  <span class="font-s-18">温度：</span>
                  <span class="font-s-18">{{
                    siteItemVo.atmosphericTemperature
                  }}</span>
                  <span class="font-s-18">(℃)</span>
                </div>
                <div class="weatherItem flex flex-a-c">
                  <span class="font-s-18">湿度：</span>
                  <span class="font-s-18">{{
                    siteItemVo.atmosphericHumidity
                  }}</span>
                  <span class="font-s-18">(RH)</span>
                </div>
              </div>
              <div class="weatherImg">
                <p>当前降水量</p>
                <img
                  src="../../assets/images/UseWater/wearther1.gif"
                  alt=""
                  v-if="siteItemVomm > 0"
                />
                <img
                  src="../../assets/images/UseWater/wearther.png"
                  alt=""
                  v-else
                />
                <p>{{ siteItemVomm }}(mm)</p>
              </div>
            </div>
          </div>
          <!-- 右侧 -->
          <div class="right">
            <!-- 太阳能信息 -->
            <div class="solar Card-b-c" v-if="isMains == 0 ? true : false">
              <Titles Titles="太阳能信息" />
              <div class="solarBox font-w-b">
                <div class="solarItem flex flex-a-c">
                  <span class="font-s-18">光伏电压：</span>
                  <span class="font-s-18">{{
                    Number(siteItemVo.pvVoltage).toFixed(1)
                  }}</span>
                  <span class="font-s-18">(V)</span>
                </div>
                <div class="solarItem flex flex-a-c">
                  <span class="font-s-18">光伏电流：</span>
                  <span class="font-s-18">{{
                    Number(siteItemVo.pvCurrent).toFixed(1)
                  }}</span>
                  <span class="font-s-18">(A)</span>
                </div>
                <div class="solarItem flex flex-a-c">
                  <span class="font-s-18">电池温度：</span>
                  <span class="font-s-18">{{
                    Number(siteItemVo.batteryTemperature).toFixed(1)
                  }}</span>
                  <span class="font-s-18">(℃)</span>
                </div>
                <div class="solarItem flex flex-a-c">
                  <span class="font-s-18">电池电压：</span>
                  <span class="font-s-18">{{
                    Number(siteItemVo.batteryVoltage).toFixed(1)
                  }}</span>
                  <span class="font-s-18">(V)</span>
                </div>
                <div class="solarItem flex flex-a-c">
                  <span class="font-s-18">日发电量：</span>
                  <span class="font-s-18">{{
                    Number(siteItemVo.dailyOutput).toFixed(1)
                  }}</span>
                  <span class="font-s-18">(KWH)</span>
                </div>
                <div class="solarItem flex flex-a-c">
                  <span class="font-s-18">剩余电量：</span>
                  <span class="font-s-18">{{
                    Number(siteItemVo.soc).toFixed(1)
                  }}</span>
                  <span class="font-s-18">(%)</span>
                </div>
                <div class="solarItem flex flex-a-c">
                  <span class="font-s-18">负载电流：</span>
                  <span class="font-s-18">{{
                    Number(siteItemVo.loadCurrent).toFixed(1)
                  }}</span>
                  <span class="font-s-18">(A)</span>
                </div>
                <!-- <div class="solarItem flex flex-a-c ">
                  <span class="font-s-18">警戒电量：</span>
                  <el-input v-model="alertPower" @blur="getBluralertPower" />
                  <span class="font-s-18">(%)</span>
                </div> -->
              </div>
            </div>
            <!-- 市电信息 -->
            <div class="isMains Card-b-c" v-else>
              <Titles Titles="市电信息" />
              <div class="isMainsBox font-w-b">
                <div class="isMainsItem flex flex-a-c">
                  <span class="font-s-18">A相电流：</span>
                  <span class="font-s-18">{{
                    Number(siteItemVo.aCurrent).toFixed(1)
                  }}</span>
                  <span class="font-s-18">(A)</span>
                </div>
                <div class="isMainsItem flex flex-a-c">
                  <span class="font-s-18">B相电流：</span>
                  <span class="font-s-18">{{
                    Number(siteItemVo.bCurrent).toFixed(1)
                  }}</span>
                  <span class="font-s-18">(A)</span>
                </div>
                <div class="isMainsItem flex flex-a-c">
                  <span class="font-s-18">C相电流：</span>
                  <span class="font-s-18">{{
                    Number(siteItemVo.cCurrent).toFixed(1)
                  }}</span>
                  <span class="font-s-18">(A)</span>
                </div>
                <div class="isMainsItem flex flex-a-c">
                  <span class="font-s-18">N相电流：</span>
                  <span class="font-s-18">{{
                    Number(siteItemVo.dCurrent).toFixed(1)
                  }}</span>
                  <span class="font-s-18">(A)</span>
                </div>
                <div class="isMainsItem flex flex-a-c">
                  <span class="font-s-18">A相电压：</span>
                  <span class="font-s-18">{{
                    Number(siteItemVo.aVoltage).toFixed(1)
                  }}</span>
                  <span class="font-s-18">(V)</span>
                </div>
                <div class="isMainsItem flex flex-a-c">
                  <span class="font-s-18">B相电压：</span>
                  <span class="font-s-18">{{
                    Number(siteItemVo.bVoltage).toFixed(1)
                  }}</span>
                  <span class="font-s-18">(V)</span>
                </div>
                <div class="isMainsItem flex flex-a-c">
                  <span class="font-s-18">C相电压：</span>
                  <span class="font-s-18">{{
                    Number(siteItemVo.cVoltage).toFixed(1)
                  }}</span>
                  <span class="font-s-18">(V)</span>
                </div>
              </div>
            </div>
            <!-- 更新时间 -->
            <div class="update Card-b-c font-s-18 t-x font-w-b">
              <p>更新时间</p>
              <span>{{
                siteItemVo.updateTime
                  ? new Date(siteItemVo.updateTime)
                      .toLocaleString("chinese", {
                        hour12: false,
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })
                      .replace(/\//g, "-") +
                    " " +
                    new Date(siteItemVo.updateTime).toLocaleString("chinese", {
                      hour12: false,
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    })
                  : siteItemVo.updateTime
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 监控视频 -->
    <div class="StationOne-right Card-b-c">
      <Titles Titles="监控视频" />
      <div
        class="video flex flex-wrap"
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
      >
        <!-- 暂无数据 -->
        <div
          class="flex flex-a-c flex-j-c"
          style="width: 100%; height: 100%"
          v-if="total === 0"
        >
          <img
            src="../../assets/images/index/video.png"
            style="
              width: calc(330 / 1920 * 100vw);
              height: calc(329 / 1080 * 100vh);
            "
          />
        </div>
        <div class="videoItem" v-else>
          <div class="btn-arrow">
            <i
              class="el-icon-arrow-left"
              :style="{
                'pointer-events': page > 1 ? '' : 'none',
                opacity: page > 1 ? '1' : '0',
              }"
              @click="nextpage(page - 1)"
            ></i>
            <i
              class="el-icon-arrow-right"
              :style="{
                'pointer-events': page !== total ? '' : 'none',
                opacity: page !== total ? '1' : '0',
              }"
              @click="nextpage(page + 1)"
            ></i>
          </div>
          <div class="box-video">
            <!-- <div id="videoList" style="width: 100%; height: 100%"></div> -->
            <iframe
              style="width: 100%; height: 100%"
              allow="camera *; microphone *"
              :allowfullscreen="true"
              :src="`https://camera.xuruidea.com/index.html?list=${encodeURIComponent(
                JSON.stringify(videoList)
              )}&num=${1}&width=${(1012 / 1920) * windowWidth}&height=${
                (860 / 1080) * windowHeight
              }`"
              frameborder="0"
            ></iframe>
            <!-- <BtnVideo
              class="icoVideo"
              v-if="videoList.videoType === 0"
              :accessToken="videoList.accessToken"
              :channelNo="videoList.channelNo"
              :deviceSerial="videoList.videoModel"
            /> -->
            <!-- <div class="box-video1">
              <div class="videoOne">
               <div id="videoList" style="width: 100%; height: 100%"></div>
                <BtnVideo
                    class="icoVideo"
                    v-if="videoList.videoType === 0"
                    :accessToken="videoList.accessToken"
                    :channelNo="videoList.channelNo"
                    :deviceSerial="videoList.videoModel"
                  />
              </div>
             
            </div>
            <div class="box-video2">
              <div ></div>
              <div ></div>
              <div></div>
            </div> -->
          </div>
        </div>

        <div id="voice-dev" v-show="voiceType"></div>
      </div>
      <!-- 分页区域 -->
      <!-- <el-pagination
        :hide-on-single-page="true"
        background
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        @prev-click="nextpage"
        @next-click="nextpage"
        @current-change="nextpage"
      >
      </el-pagination> -->
    </div>
  </div>
</template>

<script>
import Titles from "../../components/Titles";
import Anmation from "./Anmation";
// 闸门信息 气象信息 接口
import { getSiteByIdList } from "../../api/index";
import { getSitesList, getSitesIdList, getGateMessage } from "../../api/main";
import BtnVideo from "../../components/BtnVideo/index.vue";
import axios from "../../common/axiosHttp";

export default {
  components: { Titles, Anmation, BtnVideo },
  data() {
    return {
      loading: false,
      gateShow: false, //控制闸门图片
      siteId: "", // 站点id
      siteList: [], //站点列表
      siteName: "", // 战点名称
      options: [], //阀门列表
      size: 1, // 当页显示条数
      page: 1, // 当前页
      num: 0, // 第几个阀门
      deviceAddress: "",
      gateVo: {
        gateHeight: 0,
        gateHeightLimit: 0,
      }, // 闸门信息
      siteItemVo: {}, // 气象信息之类的
      warningLevel: "",
      siteItemVomm: "",
      videoList: {}, // 视频列表
      videoUrl: [], // 视频地址
      pageSize: 0,
      total: 0, // 视频总数
      // 表单区域
      isMains: "",
      valveForm: {
        value: "",
        valveHeight: "", //阀门高度
      },
      playerInstance: null,
      rules: {
        value: [{ required: true, message: "请输入用户名", trigger: "blur" }],
      },
      voiceType: false,
      windowWidth: 1920, //获取屏幕宽度,
      windowHeight: 1080, //获取屏幕高度,
    };
  },
  computed: {},
  watch: {
    // 监听路由变化
    $route: "myroute",
    "$store.state.wsSiteData"(e) {
      //console.log(e);
      if (e.id === Number(this.$route.params.siteId)) {
        // console.log(e.warningLevel);
        // console.log(this.siteItemVomm.warningLevel);
        // if(e.warningLevel==this.siteItemVomm.warningLevel){
        //       console.log(e);
        //       console.log('11111');
        //       // setTimeout(()=>{

        //       // },10000)
        //     }
        this.siteItemVo = { ...e };
        this.isMains = e.isMains;
      }
    },
    warningLevel(val) {
      // console.log(val.length);
      let docInput1 = document.getElementById("input1");
      switch (val.length) {
        case 1:
          docInput1.style = "width:30px";
          break;
        case 2:
          docInput1.style = "width:40px";
          break;
        case 3:
          docInput1.style = "width:50px";
          break;
        case 4:
          this.$message.error("不要超出输出长度");
          break;
      }
    },
    "$store.state.wsGateData"(e) {
      //  console.log(e);
      for (const i of e) {
        if (i.siteId === Number(this.$route.params.siteId)) {
          if (i.num === this.num) {
            this.gateVo = { ...i };
          }
        }
      }
      // console.log('gate', e)
    },
    "$store.state.wslRegimeDto"(e) {
      console.log(e);
      if (e.deviceAddress == this.deviceAddress) {
        this.siteItemVomm = e.rainfall;
      }
      // if (e.id === Number(this.$route.params.siteId)) {
      //   this.siteItemVo = { ...e };
      //   this.isMains = e.isMains;
      // }
    },
  },
  methods: {
    //警戒线
    test() {
      axios
        .post(`/site/updateSiteLevel`, {
          id: this.$route.params.siteId,
          warningLevel: this.warningLevel,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message({
              message: res.message,
              type: "success",
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
      //  this.$post(
      //     `/videoAlarmRecord/deleteByIds?ids=${this.checkList}`
      //   ).then(res => {
      //     if (res.code == 200) {
      //       this.$message({
      //         type: "success",
      //         message: res.message
      //       });
      //       this.size = 6;
      //       if (this.total % 6 == 1) {
      //         this.page--;
      //       }
      //       this.checkList = [];
      //       this.getCaptureList();
      //       this.isCheckedAll = false;
      //     }
      //   });
    },
    myroute() {
      this.page = 1;
      // 重新赋值
      this.siteId = this.$route.params.siteId;
      this.getName();
      this.ValveList();
      this.getGate();
    },
    // 获取阀门列表
    ValveList() {
      getSitesIdList({ id: this.siteId }).then((res) => {
        this.options = res.data;
        const video = document.querySelector("#videoList");
        if (video) video.innerHTML = "";
        this.total = 0;
        // 清空语音 DOM 内容，因为闸门不存在时，不执行获取语音设备的操作
        const voice = document.querySelector("#voice-dev");
        if (voice) voice.innerHTML = "";
        this.voiceType = false;
        if (this.options.length !== 0) {
          this.num = this.options[0].num;
          this.valveForm.value = this.options[0].id;
          this.getGateVoice();
          // 切换闸门信息
          getGateMessage({
            id: this.valveForm.value,
            size: this.size,
            page: this.page,
            num: this.num,
            siteId: this.siteId,
          }).then((res) => {
            this.loading = true;
            // 每次重新清空
            this.videoList = res.data.videoInfo.list[0];
            console.log(res);
            this.total = res.data.videoInfo.total;
            this.pageSize = res.data.videoInfo.pageSize;
            setTimeout(() => {
              const video = document.querySelector("#videoList");
              if (video) {
                video.innerHTML = "";
                this.playerInstance = new this.$ezuikit.EZUIKitPlayer({
                  autoplay: true,
                  id: "videoList",
                  header: ["capturePicture", "save"],
                  footer: ["hd", "fullScreen"],
                  accessToken: this.videoList.accessToken,
                  url: this.videoList.videoAddress,
                  template: "simple",
                  audio: 0,
                });
              }
            }, 500);
            this.loading = false;
          });
        } else {
          this.valveForm.value = "无数据";
        }
      });
    },

    // 翻页
    nextpage(value) {
      console.log(value);
      this.page = value;
      // 切换闸门信息
      getGateMessage({
        id: this.valveForm.value,
        size: this.size,
        page: this.page,
        num: this.num,
        siteId: this.siteId,
      }).then((res) => {
        this.loading = true;
        // 每次重新清空
        this.videoList = res.data.videoInfo.list[0];
        this.total = res.data.videoInfo.total;
        this.pageSize = res.data.videoInfo.pageSize;
        setTimeout(() => {
          const video = document.querySelector("#videoList");
          if (video) {
            video.innerHTML = "";
            new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: "videoList",
              header: ["capturePicture", "save"],
              footer: ["hd", "fullScreen"],
              accessToken: this.videoList.accessToken,
              url: this.videoList.videoAddress,
              template: "simple",
              audio: 0,
              //  height: 470,
            });
          }
        }, 500);
        this.loading = false;
      });
    },
    // 切换闸门
    cutGate(value) {
      console.log(value);
      // this.gateShow
      const id = value;
      // 获取闸门 num
      for (const i of this.options) {
        if (i.id === id) {
          this.num = i.num;
          i.num > 5 ? (this.gateShow = false) : (this.gateShow = true);
        }
      }
      this.getGateVoice();
      // 切换闸门信息
      getGateMessage({
        id,
        size: this.size,
        page: 1,
        num: this.num,
        siteId: this.siteId,
      }).then((res) => {
        this.gateVo = {};
        this.gateVo = res.data.gateVo;
        // 每次重新清空
        this.videoList = res.data.videoInfo.list[0];
        this.total = res.data.videoInfo.total;
        this.pageSize = res.data.videoInfo.pageSize;
        setTimeout(() => {
          const video = document.querySelector("#videoList");
          if (video) {
            video.innerHTML = "";
            new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: "videoList",
              header: ["capturePicture", "save"],
              footer: ["hd", "fullScreen"],
              accessToken: this.videoList.accessToken,
              url: this.videoList.videoAddress,
              template: "simple",
              audio: 0,
            });
          }
        }, 500);
      });
    },
    // 获取站点名称
    getName() {
      // 获取列表接口
      getSitesList().then((res) => {
        this.siteList = res.data;
        this.siteList.forEach((item) => {
          if (item.id == this.siteId) {
            // 站点名称赋值
            this.siteName = item.siteName;
            console.log(item.deviceAddress);
            this.deviceAddress = item.deviceAddress;
            axios
              .get(
                `/rainfallRegime/getRainfallByDevice?deviceAddress=${item.deviceAddress}`
              )
              .then((res) => {
                this.siteItemVomm = res.data;
              });
          }
        });
      });
    },
    // 获取闸门信息
    getGate() {
      getSiteByIdList({ id: this.siteId }).then((res) => {
        console.log(res);
        if (res.data.gateVo) {
          this.gateVo = res.data.gateVo;
        } else {
          Object.keys(this.gateVo).forEach((value) => {
            this.gateVo[value] = "";
          });
        }
        this.siteItemVo = res.data.siteItemVo;
        this.warningLevel = res.data.siteItemVo.warningLevel;
        this.warningLevel = Number(res.data.siteItemVo.warningLevel).toFixed(0);
        this.alertPower = res.data.siteItemVo.alertPower;
        this.isMains = res.data.isMains;
      });
    },
    // 获取闸门语音设备信息
    async getGateVoice() {
      try {
        let gateId = null;
        for (const i of this.options) {
          if (i.num === this.num) gateId = i.id;
        }
        // 清空语音 DOM 内容
        const voice = document.querySelector("#voice-dev");
        if (voice) voice.innerHTML = "";
        this.voiceType = false;
        // gateId 不存在时返回
        if (!gateId) return;
        const req = await axios.get("/video/getVideoCall", {
          params: { gateId },
        });
        if (req.data) {
          this.voiceType = true;
          if (voice) {
            new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: "voice-dev",
              accessToken: req.data.accessToken,
              url: req.data.videoAddress,
              template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
              // 视频上方头部控件
              //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
              plugin: ["talk"], // 加载插件，talk-对讲
              // 视频下方底部控件
              footer: ["talk", "broadcast", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
              audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
            });
          }
        }
      } catch (err) {
        this.$message.error(err.message);
      }
    },
    // 开启闸门
    async startSluice() {
      try {
        if (localStorage.getItem("roleId") == 82) {
          throw new Error("暂无权限，请联系管理员！");
        }
        if (!this.valveForm.valveHeight) {
          throw new Error("闸门高度不能为空");
        }
        if (!/^[0-9]+$/.test(this.valveForm.valveHeight)) {
          throw new Error("闸门高度必须为大于0的整数");
        }
        const num = Number(this.valveForm.valveHeight);
        if (/\./.test(num) || num <= 0) {
          throw new Error("闸门高度必须为大于0的整数");
        }
        if (num > Number(this.gateVo.gateHeightLimit)) {
          throw new Error("闸门高度不能超过限高");
        }
        const data = {};
        data.siteId = this.siteId;
        data.gateHeight = this.valveForm.valveHeight;
        for (const i of this.options) {
          if (i.num === this.num) {
            data.id = i.id;
            data.num = i.num;
            data.deviceAddress = i.deviceAddress;
          }
        }
        await this.$axios.post("./gate/setGate", data);
        this.$message.success("闸门已响应");
        // this.gateVo.gateHeight = num;
      } catch (err) {
        if (err.message) {
          this.$message.error(err.message);
          return;
        }
        if (err.data.msg) {
          this.$message.error(err.data.msg);
          return;
        }
      }
    },
    // 关闭闸门
    async stopSluice(type) {
      try {
        if (localStorage.getItem("roleId") == 82) {
          throw new Error("暂无权限，请联系管理员！");
        }
        const data = { type };
        data.siteId = this.siteId;
        for (const i of this.options) {
          if (i.num === this.num) {
            data.id = i.id;
            data.num = i.num;
            data.deviceAddress = i.deviceAddress;
          }
        }
        await this.$axios.post("./gate/stopGate", data);
        this.$message.success("闸门已响应");
        // this.gateVo.gateHeight = 0;
      } catch (err) {
        if (err.message) {
          this.$message.error(err.message);
          return;
        }
        if (err.data.msg) {
          this.$message.error(err.data.msg);
          return;
        }
      }
    },
  },
  created() {
    this.myroute();
  },
  mounted() {
    this.windowWidth = document.documentElement.clientWidth;
    this.windowHeight = document.documentElement.clientHeight;
  },
};
</script>
<style lang="scss" scoped>
.box-video {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .box-video1 {
    height: 65%;
    display: flex;
    justify-content: space-between;
    .videoOne {
      position: relative;
      width: 100%;
      background: #000;
    }
    // .videoTwo {
    //   width: 32%;
    //   display: flex;
    //   justify-content: space-between;
    //   flex-direction: column;
    //   div {
    //     background: #000;
    //     height: 48%;
    //     border: solid 1px;
    //   }
    // }
  }
  .box-video2 {
    width: 100%;
    height: 33%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    div {
      background: #000;
      width: 32%;
    }
  }
}
.StationOne {
  .StationOne-left {
    width: calc(522 / 1920 * 100vw);
    .FourDo {
      height: calc(356 / 1080 * 100vh);
      margin-bottom: calc(13 / 1080 * 100vh);
    }
    // 闸门信息
    .strobe {
      height: calc(113 / 1080 * 100vh);
      margin-bottom: calc(13 / 1080 * 100vh);
      .strobeBox {
        height: calc(55 / 1080 * 100vh);
        .strobeBox-height {
          width: 50%;
          height: calc(23 / 1080 * 100vh);
          span:nth-child(1) {
            color: #666666;
            text-indent: calc(15 / 1920 * 100vw);
          }
          span:nth-child(2) {
            color: #22ac38;
          }
          span:nth-child(3) {
            color: #333333;
          }
        }
        .strobeBox-headroom {
          width: 50%;
          height: calc(23 / 1080 * 100vh);
          span:nth-child(1) {
            color: #666666;
            text-indent: calc(15 / 1920 * 100vw);
          }
          span:nth-child(2) {
            color: #00a0e9;
          }
          span:nth-child(3) {
            color: #333333;
          }
        }
      }
    }
    //水文，太阳能
    .StationOne-left-bottom {
      .content {
        .left {
          width: calc(252 / 1920 * 100vw);
          // 水文信息
          .hydrology {
            width: 100%;
            height: calc(250 / 1080 * 100vh);
            margin-bottom: calc(13 / 1080 * 100vh);
            .hydrologyBox {
              // 当前水位
              .presentWater {
                height: calc(50 / 1080 * 100vh);
                span:nth-child(1) {
                  color: #666666;
                  text-indent: calc(15 / 1920 * 100vw);
                }
                span:nth-child(2) {
                  color: #3cb24c;
                }
                span:nth-child(3) {
                  color: #333333;
                }
              }
              // 警戒水位
              .warningWater {
                // .el-input {
                //   width: calc(80 / 1920 * 100vw);
                //   .el-input__inner {
                //     width: calc(80 / 1920 * 100vw) !important;
                //     height: calc(38 / 1080 * 100vh) !important;
                //     color: red !important;
                //     font-weight: bold !important;
                //     font-size: calc(18 / 1080 * 100vh);
                //   }
                // }
                height: calc(38 / 1080 * 100vh);
                span:nth-child(1) {
                  color: #666666;
                  text-indent: calc(15 / 1920 * 100vw);
                }
                span:nth-child(2) {
                  color: #0196ff;
                }
                span:nth-child(3) {
                  color: #333333;
                }
              }
            }
          }
          // 气象信息
          .weather {
            position: relative;
            height: calc(200 / 1080 * 100vh);
            .weatherBox {
              height: calc(152 / 1080 * 100vh);
              .weatherItem {
                height: calc(50 / 1080 * 100vh);
                color: #333333;
                span:nth-child(1) {
                  text-indent: calc(15 / 1920 * 100vw);
                  color: #666666;
                }
              }
            }
          }
        }
        ::v-deep .right {
          width: calc(252 / 1920 * 100vw);
          // 太阳能信息
          .solar {
            width: 100%;
            height: calc(390 / 1080 * 100vh);
            margin-bottom: calc(13 / 1080 * 100vh);
            .solarBox {
              height: calc(342 / 1080 * 100vh);
              .solarItem {
                height: calc(48 / 1080 * 100vh);
                color: #333333;
                span:nth-child(1) {
                  text-indent: calc(15 / 1920 * 100vw);
                  color: #666666;
                }
              }
              .el-input {
                width: calc(80 / 1920 * 100vw);
                .el-input__inner {
                  width: calc(80 / 1920 * 100vw) !important;
                  height: calc(38 / 1080 * 100vh) !important;
                  color: red !important;
                  font-weight: bold !important;
                  font-size: calc(18 / 1080 * 100vh);
                }
              }
            }
          }
          .isMains {
            width: 100%;
            height: calc(390 / 1080 * 100vh);
            margin-bottom: calc(13 / 1080 * 100vh);
            .isMainsBox {
              height: calc(342 / 1080 * 100vh);
              .isMainsItem {
                height: calc(49 / 1080 * 100vh);
                color: #333333;
                span:nth-child(1) {
                  text-indent: calc(15 / 1920 * 100vw);
                  color: #666666;
                }
              }
              .el-input {
                width: calc(80 / 1920 * 100vw);
                .el-input__inner {
                  width: calc(80 / 1920 * 100vw) !important;
                  height: calc(30 / 1080 * 100vh) !important;
                }
              }
            }
          }
          // 更新时间
          .update {
            height: calc(60 / 1080 * 100vh);
            color: #0196ff;
            p {
              padding-top: calc(5 / 1080 * 100vh);
            }
          }
        }
      }
    }
  }
  // 监控视频
  .StationOne-right {
    width: calc(1040 / 1920 * 100vw);
    height: calc(960 / 1080 * 100vh);
    box-sizing: border-box;
    margin-left: calc(16 / 1920 * 100vw);
    .video {
      position: relative;
      margin: calc(11 / 1080 * 100vh) calc(11 / 1920 * 100vw);
      height: calc(100% - calc(72 / 1080 * 100vh));
      #voice-dev {
        position: absolute !important;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        ::v-deep iframe {
          display: none;
        }
        ::v-deep #voice-list {
          overflow-y: auto;
        }
        ::v-deep #fullScreen {
          display: none;
        }
      }
      .videoItem {
        position: relative;
        width: 100%;
        height: 100%;
        // background: #000;
        .btn-arrow {
          position: absolute;
          top: 50%;
          display: flex;
          justify-content: space-between;
          width: calc(100% - calc(40 / 1920 * 100vw));
          padding: 0 calc(20 / 1920 * 100vw);
          z-index: 99;
          > i {
            background: #fff;
            color: #000;
            padding: 10px;
            border-radius: 50%;
            cursor: pointer;
            transform: translateY(-50%);
            &:active {
              transform: translateY(-37.5%);
            }
          }
        }
      }
    }
    ::v-deep .el-pagination {
      margin-top: calc(40 / 1080 * 100vh);
    }
  }
}
// 动画区域
.animation {
  width: 100%;
  height: calc(200 / 1080 * 100vh);
}

// 阀门
.valve {
  color: #333333;
  .el-form {
    margin-left: calc(30 / 1920 * 100vw);
    .el-form-item {
      margin-bottom: calc(10 / 1080 * 100vh);
      ::v-deep .el-select {
        width: calc(162 / 1920 * 100vw);
        margin-right: calc(21 / 1920 * 100vw);
        .el-input__inner {
          height: calc(38 / 1080 * 100vh) !important;
        }
      }
    }
    .valve-h {
      width: 100%;
      ::v-deep .el-input__inner {
        width: calc(72 / 1920 * 100vw);
        height: calc(38 / 1080 * 100vh);
      }
      ::v-deep .el-form-item__content {
        display: flex;
      }
    }
  }
}

// 急停
.el-button + .el-button {
  margin-left: 0;
}
.el-button {
  background: #d9d9d9;
}
// 启动按钮
.start {
  border-radius: 4px 0 0 4px !important;
}
// 急停按钮
.stop {
  border-radius: 0 4px 4px 0 !important;
}
::v-deep .el-input__icon {
  font-size: calc(10 / 1920 * 100vw) !important;
}
.weatherImg {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #666666;
  font-weight: bold;
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}
.table-num {
  ::v-deep .el-input__inner {
    width: 32px;
    font-size: 1.2rem;
    height: 33px;
    color: #ff5955;
    padding: 0;
    padding-left: 3px;
  }
}
.icoVideo {
  position: absolute;
  top: 7px;
  right: 70px;
  z-index: 999;
}
</style>
