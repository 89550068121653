var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[(_vm.zhaStatus)?_c('div',{staticClass:"zhamen-box",class:{
      'zhamen-bg1': _vm.gateList.length === 1,
      'zhamen-bg2': _vm.gateList.length === 2,
      'zhamen-bg3': _vm.gateList.length === 3,
      'zhamen-bg4': _vm.gateList.length === 4,
      'zhamen-bg5': _vm.gateList.length >= 5
    }},[_c('ul',{staticClass:"menUl"},_vm._l((_vm.gateList.slice(0, 5)),function(item){return _c('li',{key:item.id,class:("switch" + (item.id)),style:({ top: ("calc((" + (95 - Number(item.gateHeight) / Number(item.gateHeightLimit) * 20) + ") / 1920 * 100vw)") })})}),0),_vm._l((_vm.gateList.slice(0, 5)),function(item,index){return _c('div',{key:item.id,staticClass:"num",class:("num" + (index + 1))},[_vm._v(_vm._s(index + 1))])})],2):_vm._e(),(_vm.gateList.length > 5 && !_vm.zhaStatus)?_c('div',{staticClass:"zhamen-box",class:{
      'zhamen-bg1': _vm.gateList.length === 6,
      'zhamen-bg2': _vm.gateList.length === 7,
      'zhamen-bg3': _vm.gateList.length === 8,
      'zhamen-bg4': _vm.gateList.length === 9,
      'zhamen-bg5': _vm.gateList.length === 10
    }},[_c('ul',{staticClass:"menUl"},_vm._l((_vm.gateList.slice(5, 10)),function(item){return _c('li',{key:item.id,class:("switch" + (item.id)),style:({ top: ("calc((" + (95 - Number(item.gateHeight) / Number(item.gateHeightLimit) * 20) + ") / 1920 * 100vw)") })})}),0),_vm._l((_vm.gateList.slice(5, 10)),function(item,index){return _c('div',{key:item.id,staticClass:"num",class:("num" + (index + 1))},[_vm._v(_vm._s(index + 6))])})],2):_vm._e(),(_vm.gateList.length > 5)?_c('div',{staticClass:"btn left-btn",on:{"click":function($event){_vm.zhaStatus = _vm.zhaStatus ? false : true}}}):_vm._e(),(_vm.gateList.length > 5)?_c('div',{staticClass:"btn right-btn",on:{"click":function($event){_vm.zhaStatus = _vm.zhaStatus ? false : true}}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }